@import '../../theme/styles/var';

.container {
  margin-bottom: 40px;
  padding: 10px 15px;
  border: 1px solid $colorLightGray;
  border-radius: 5px;
}

.text {
  font-size: 14px;
  color: $colorSpanishGray;
  margin-bottom: 10px;
}

.textColor {
  color: $colorBlack;
  font-weight: 600;
}

.colors {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: $mobMin) and (max-width: $mobMax) {
    display: none;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  // width: 33px;
  height: 33px;
  padding: 0 2px;
  background-color: none;
  border-radius: 5px;

  @media (min-width: $deskMin) and (max-width: $deskMax) {
    // width: 25px;
    height: 25px;
  }
}

.itemActive {
  border: 1px solid $colorCyan;
  border-radius: 5px;
  background-color: $colorGhostWhite;

  & .color {
    opacity: 0.9;
  }

  & a {
    cursor: auto;
  }
}

.border {
  background-color: none;
  border-radius: 3px;
  border: 2px solid;
  // width: 25px;
  height: 25px;
  @media (min-width: $deskMin) and (max-width: $deskMax) {
    // width: 20px;
    // height: 20px;
  }
}

.color {
  opacity: 0.9;
  width: 100%;
  height: 100%;
}
