@import '../../../theme/styles/mixins';
@import '../../../theme/styles/var';

.container {
  @extend %custom-shadow;
  @extend %custom-border;
  background-color: $colorWhite;
  padding: 20px;
}

.previewContant {
  border: 1px solid $colorSpanishGray;
  border-radius: 5px;
}

.title {
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}

.cartItem {
  flex-direction: column;
}

.ordersBlock {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 10px;
}

.itemTitle {
  font-size: 14px;
}

.payment {
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid $colorSpanishGray;
  font-weight: 700;
}

@media screen and (max-width: $mobMax) {
  .container {
    width: 100%;
  }
}