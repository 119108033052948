.bg__disabled{
    background-color: #00000037!important;
}
.cartWrapper{
    display: flex;
    flex-direction: column;
}
.cartItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    // background-color: #25252518;
    border-bottom: 1px solid #e0e0e0;
    border: 1px solid #e0e0e0;

    // height: 150px;
    margin-bottom: 5px;
    transition: all ease-in-out .3s;
    &:last-child {
      border-bottom: none;
    }
  
    .itemIndex {
        width: 20px ;
        text-align: center;
        font-weight: bold;
    }
  
    .itemImage {
        text-align: center;
        width: 100%;
        padding-left: 5%;
      img {
        max-width: 100px; /* Set a fixed maximum width for the image */
        height: auto;
        border-radius: 4px;
        object-fit: cover;
      }
    }
    .itemDel {
        width: 30px;
        height: 30px;
        padding: 0;
    }
    &:hover {
        // background-color: #00000018;
        transform: scale(101%);
    }
  }
  
  // Add responsiveness for mobile devices
  @media (max-width: 768px) {
    .cartItem {
      align-items: flex-start;
  
      .itemIndex {
        width: 5%;
        margin-bottom: 10px;
        text-align: left;
      }
  
      .itemImage {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
  
        img {
          max-width: 100%;
        }
      }
    }
  }
  