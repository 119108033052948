.cart{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 1rem;
}
.cart__img{
    max-width: 100%;
    img{
        width: 100%;
    }
}
.cart__title{
    text-align: start;
    a{
        margin-top: 1rem;
        font-weight: 600;
        line-height: 1.2;
        color: black;
        &:hover{
            text-decoration: underline;
            text-decoration-color: black
        }
    }
}
.cart__markLine{
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-bottom: 1.5rem;
}
.cart__mark{
    text-align: start!important;

    color: #727272;
    font-size: .8125rem;
    margin-top: 0.5rem;
    margin-right: 10px;
}