.filter {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.filterWrapper{
  display: flex;
  gap: 10px;
  width: 350px;
  input{
    width: 250px;
    padding: 10px;
    line-height: 1.15px;
  }
}
.filter__block{
  background-color: #0c0b0b;
  padding: 10px;
}
