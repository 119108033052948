.card-body {
    padding: 5px!important;
}
// .goods__tile-wrapper {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     z-index: 10;
// }
.goods-tile{
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    // width: 98%;
    // margin-top: 1%;
    // margin-left: 1%;
    // height: 99%!important;
    overflow: hidden;
    cursor: pointer;
}
.goods-tile:focus, .goods-tile:hover {
    overflow: visible;
}
.card_image{
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
}
.discount-badge {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: black;
    color: white;
    font-size: 12px;
    padding: 10px 10px;
    border-radius: 3px;
    z-index: 1;
    font-weight: bold;
}
.goods-tile__name-block {
    max-width: 100%;
    max-height: 50px;
    overflow: hidden;
}
.goods-tile__name {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp, 2);
    -webkit-box-orient: vertical;
    height: 2.5em;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px;
    padding-right: 20px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
}

// .animated_card {
//     overflow: hidden;
//     border-radius: 8px;
// }
// .animated_card::before {
//     position: absolute;
//     content: '';
//     width: 50%;
//     height: 180%;
//     top: -20%;
//     left: 20%;
//     background-color: black;
//     transform: rotate(45deg);
//     border-radius: 3px;
//     z-index: 0;
// }
// .animated_card:hover::before {
//     animation: animate 2s linear infinite;
// }
// @keyframes animate {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(360deg);
//     }
// }
// .animated_card::after {
//     position: absolute;
//     content: '';
//     inset: 5px;
//     // background-color: #fff;
//     border: 8px;
//     z-index: 0;
// }