@import '../../../../theme/styles/var';

.bold {
  font-weight: 700;
}

.info {
  position: relative;
  font-size: 14px;
  line-height: 2;
}

.specify {
  font-weight: 700;
  position: absolute;
  top: -25px;
  right: 5px;
  padding: 0 5px;
  cursor: pointer;
  &:hover {
    color: $colorCyan;
    border-bottom: 2px solid $colorCyan;
  }
}

.productList {
  border: 1px solid $colorSpanishGray;
  border-radius: 5px;
}
