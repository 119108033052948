@import '../../../theme/styles/var';

.container {
  padding-top: 20px;
  position: relative;
  border: 1px solid $colorSpanishGray;
  border-radius: 5px;
}

.filter {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.filterWrapper{
  display: flex;
  flex-direction: column;
  input{
    width: 250px;
  }
}
.select {
  padding: 3px 5px;
  width: 250px;

}
