.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .tab__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    color: #000;
    outline: none;
    transition: all 0.3s ease;
    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
  
  .tab__button.active {
    color: #fff;
    background-color: #000;
  }
  
  .tab__content {
    padding: 20px;
    text-align: start;
  }
  
  .tab__content p {
    text-wrap: wrap;
    margin: 0;
  }
  
  .tab__content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .tab__content ul li {
    margin-bottom: 5px;
  }
  