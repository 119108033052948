.pageName{
    // width: calc(100% - 1rem);
    text-align: start;
    margin: 0 1rem;
}
.pageTitleWrapper{
    // width: calc(100% - 1rem);
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    border: none;
    border-left: 1px solid black;
    box-shadow: 0px 1px 7px 0px #c5c5c5;
    margin: 0 1rem;
    h2{
        font-size: 20px;
        text-align: start;
    }
}
.carts{
    display: flex;
    padding: 1rem;
}