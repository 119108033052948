@import '../../../theme/styles/var';

.search__container {
    position: relative;
  }
  
  .search__input {
    padding: 8px 30px 8px 10px;
    border: none;
    border-radius: 20px;
    // background-color: #d9d9d9;
    width: 40rem;
    border: solid 1px #d9d9d9;
  }

  .search_loop{
    color: #757575;
    margin-right: 10px;
  }
  
  .search__input:focus {
    outline: none;
  }
  
  .search__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
  }
  .search__button{
    position: absolute;
    border: none;
    background-color: transparent;
    top: 20%;
    right: 0;
    color: white;
  }
  /* Media query for responsive design */
  @media screen and (max-width: 768px) {
    .search__input {
      width: 75px;
    }
  }
@media (min-width: $deskXMax) {
  }

  @media (min-width: $deskXMin) and (max-width: $deskXMax) {
  }

  @media (min-width: $deskMin) and (max-width: $deskMax) {
  }

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    .search__input {
      width: 100%;
    }
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    .search__input {
      width: 130px;
    }
  }

  @media (max-width: $mobMin) {
    .search__input {
      width: 130px;
    }
  }