.containerRadio{
    display: flex;
    gap: 10px;
}
.wrapperRadio{
    display: flex;
    align-items: center;
    text-align: left;
    label{
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 1rem;
    }
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .monoPayButton, .googlePayButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .monoPayButton {
    background-color: #000;
  }
  
  .monoPayButton:hover {
    background-color: #333;
  }
  
  .mono {
    font-weight: bold;
  }
  
  .pay {
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    color: #000;
    font-weight: 700;
    padding: 2px 8px;
    border-radius: 4px;
  }
  
  .googlePayButton {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .googleIcon {
    color: #4285F4;
    font-size: 20px;
    margin-right: 8px;
  }
  
  .cardError {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e74c3c;
  }
  
  .cardErrorIcon {
    margin-right: 8px;
  }
  
  .cardErrorMessage {
    font-size: 14px;
  }
  