@import '../../../theme/styles/var';

.center {
  display: flex;
  align-items: center;
  height: 100vh;
}

.page_404 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding: 50px 15px;
  margin-right: auto;
  margin-left: auto;
}

.fourZeroFourBg {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: $deskXMax) {
    height: 500px;
  }
}
.title {
  font-size: 40px;
  margin: 0;
  @media (min-width: $tabMin) and (max-width: $tabMax) {
    font-size: 30px;
  }
  @media (max-width: $mobMax) {
    font-size: 24px;
  }
}

.contant_box_404 {
  margin-top: -60px;
  @media (max-width: $tabMax) {
    margin-top: -45px;
  }
}
.h2 {
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (min-width: $tabMin) and (max-width: $tabMax) {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media (max-width: $mobMax) {
    font-size: 16px;
  }
}
.p {
  margin-bottom: 20px;
  @media (min-width: $tabMin) and (max-width: $tabMax) {
    font-size: 14px;
    margin-bottom: 10px;
  }
  @media (max-width: $mobMax) {
    margin-bottom: 5px;
    font-size: 16px;
  }
}
