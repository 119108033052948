.menuProducts {
  display: flex;
  align-items: center;
  gap: 20px;
  background: transparent;
  height: 20vh;
  text-align: left;
  padding: 2rem;
  padding-top: 50px;
  position: absolute;
  width: 100%;
  top: -250px;
  opacity: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 0;

  &::-webkit-scrollbar {
    height: 7px;
  }
  
  &::-webkit-scrollbar-track {
    height: 7px;
    border-radius: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    height: 7px;
    border-radius: 14px;
    background-color: rgba(255, 255, 255, 0.149);
    transition: all 0.25s ease-out;
  }

  @media (max-width: 576px) {
    width: 100%;
    flex-direction: column;
    justify-content: start;
    height: 105vh;
    top: -150vh;
    padding-top: 100px;
    gap: 1rem;
    opacity: 0;
    overflow-y: auto;
    overflow-x: hidden;
     
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      width: 7px;
    }
  }

  .itemLink {
    font-size: 14px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: .8rem;
      text-align: center;
      padding: .5rem 0;
    }

    &:hover,
    &:focus {
      color: #972727;
    }
  }

  &.openProducts {
    top: -3rem;
    background: #000000; // Show menu on open
    opacity: 1;
  }
}
