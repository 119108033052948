.image-wrapper {
    position: relative;
    width: 100%;
    // max-width: 300px;
    padding-top: 100%; /* Зберігає квадратну форму */
    margin: 0 auto; /* Центрування зображення */
    overflow: hidden; /* Ховає частини, які виходять за межі */
    aspect-ratio: 1/1;
}

.product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Забезпечує збереження пропорцій */
    border-radius: 8px; /* Додає трохи заокруглення */
}
