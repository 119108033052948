@import '../../../../theme/styles/var';

.productItem {
  display: flex;
  align-items: center;
  margin: 10px 0;
  border-bottom: 1px dashed $colorSpanishGray;
  &:last-child {
    border-bottom: none;
  }
}

.productImage {
  margin-right: 10px;
}

.productName {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #000;
  &:hover {
    color: $colorCyan;
  }
}

.productPrice {
  display: flex;
  & div {
    margin-right: 10px;
  }
}
