@import "../../../theme/styles/var";
.registration__form-container-wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .registration__form-container{
         max-width: max-content;
         padding: 20px;
         border-radius: 10px;
         background-color: $colorBlackBackground;
         color: #ffffff;
    }
}