@import '../../../../theme/styles/var';

.tabContainer {
  padding: 30px;
  background-color: $colorWhite;
}

.citiesTitle {
  color: $colorSpanishGray;
  font-size: 15px;
}

.lastHr {
  margin-bottom: 30px;
}

.title {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 30px 0;
}
.addComment {
  text-align: center;
}

.comment {
  background: none !important;
  margin: 10px 0 20px;
  cursor: pointer;
  &:hover {
    color: $colorCyan;
  }
}

.textareaContainer {
  margin: 20px 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.textarea {
  width: 75%;
  border: 1px solid $colorSilverChalice;
  border-radius: 4px;
  vertical-align: middle;
  transition: border-color 0.1s;
  padding: 6px 8px;
  font-size: 16px;
  resize: none;
}