@import '../../../theme/styles/var';

.burger__products-button {
  position: absolute;
  top: 9px;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.12rem;
    background: #0D0C1D;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: rotate(0);
    }

    &:nth-child(2) {
      opacity: 1;
      transform: translateX(0);
    }

    &:last-child {
      transform: rotate(0);
    }
  }

  &.open__products {
    div {
      width: 2rem;
      height: 0.15rem;
      background: #ffffff;
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }
  }
}
@media  (max-width: $tabMax) {
  .burger__products-button {
    // top: 0px;
    left: 1rem;
  }
}