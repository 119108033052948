@import '../../../theme/styles/var';

.container {
  margin: 20px;
  padding-bottom: 10px;
  border: 1px solid $colorSpanishGray;
  border-radius: 4px;
}

.header {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  border-bottom: 1px solid $colorSpanishGray;
  border-radius: 5px;
}

.bold {
  font-weight: 700;
}

.infoBlock {
  padding: 5px 15px;
}

.infoOrderBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  & div {
    cursor: pointer;
  }
}

.info {
  font-size: 14px;
  line-height: 2;
}

.productList {
  border: 1px solid $colorSpanishGray;
  border-radius: 5px;
}
