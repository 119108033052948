
#name_shop{
    &:hover{
      color: #fff;
      text-decoration: underline;
    }
}
.icons{
  display: grid;
  place-content: center;
  width:100%;
  height:100%;
}
.site-footer{
  background-color:#000000;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}

.site-footer hr{
  border-top-color:#bbb;
  opacity:0.5
}

.site-footer hr.small{
  margin:20px 0
}

.site-footer h6{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}

.site-footer a{
  color:#737373;
  &:hover{
    color: #fff;
    text-decoration: underline;
  }
}

.site-footer a:hover{
  color:#3366cc;
  text-decoration:none;
}

.footer-links{
  padding-left:0;
  list-style:none
}

.footer-links li{
  display:block
}

.footer-links a{
  color:#737373;

}

.footer-links a:active,.footer-links a:focus,.footer-links a:hover{
  color: #fff;
  text-decoration: underline;
}

.footer-links.inline li{
  display:inline-block
}

.site-footer .social-icons{
  text-align:right
}

.site-footer .social-icons a{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}

.copyright-text{
  margin:0
}

@media (max-width:991px){

  .site-footer [class^=col-]  {
    margin-bottom:30px
  }
}

@media (max-width:767px){
  .site-footer  {
    padding-bottom:0
  }

  .site-footer .copyright-text,.site-footer .social-icons  {
    text-align:center
  }
}

.social-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left:0;
  margin-bottom:0;
  list-style:none
}

.social-icons li{
  display:inline-block;
  margin-bottom:4px
}

#aboutUs{
  cursor: pointer;
}
#aboutUs:hover{
  color: white;
  text-decoration: underline;
}

.social-icons li.title{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:gray;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}

.social-icons a:active,.social-icons a:focus,.social-icons a:hover{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.mail:hover{
  background-color:#901919
}
.social-icons a.telegram:hover{
  background-color:#00aced
}
.social-icons a.linkedin:hover{
  background-color:#007bb6
}
.social-icons a.dribbble:hover{
  background-color:#ea4c89
}
@media (max-width:767px){
  .social-icons li.title  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
i{
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #ddc2c2;
  z-index: 1;
}
