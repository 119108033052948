.cart{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 1rem;
    overflow: hidden;

    .cart__imageWrapper{
        max-width: 100%;
        overflow: hidden;
    }
    .cart__image{
        width: 100%;
        margin-bottom: 1rem;
    }
}
.cart__title{
    text-align: start;
    overflow: hidden; 
    text-overflow: ellipsis;
    width: 100%;
    a{
        margin-top: 1rem;
        font-weight: 600;
        line-height: 1.2;
        color: black;
        &:hover{
            text-decoration: underline;
            text-decoration-color: black
        }
    }
}
.cart__markLine{
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-bottom: 1.5rem;
}
.cart__mark{
    text-align: start!important;

    color: #727272;
    font-size: .8125rem;
    margin-top: 0.5rem;
    margin-right: 10px;
}