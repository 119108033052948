.order-accepted {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .order-accepted-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .order-accepted-text {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }