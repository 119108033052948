@import '../../theme/styles/var';
@import '../../theme/styles/mixins';

.breadCrumbs {
  display: flex;
  align-items: center;
  color: $colorDimGray;
  font-size: .8rem;
  margin-bottom: 10px;
  margin-left: 20px;
}

.crumbsLink {
  color: $colorDimGray;

  &:hover {
    color: $colorCyanLight;
  }
}

.iconBreadcrumbs {
  padding: 0 3px;

  &::before {
    content: '/';
    font-size: 20px;
    line-height: 0;
    position: relative;
    top: 2px;
  }
}