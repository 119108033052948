.pagination_button {
    background-color: #007bff; /* Bootstrap primary blue */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.pagination_button:hover {
    background-color: #0056b3; /* Darker blue */
}

.pagination_button:disabled {
    background-color: #cccccc; /* Light grey */
    cursor: default;
}

.back_button {
    /* Specific styles for back button if needed */
}

.next_button {
    /* Specific styles for next button if needed */
}