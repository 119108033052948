@import './var';

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: $fontFamily;
}

a {
  text-decoration: none;
}
//========
html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
}

div#root {
  min-height: 100vh;
  overflow: hidden;
   /* remove this line to see div.app is no more 100% height */
}

div.App {
  min-height: 100vh;
  // overflow: hidden;

}
//=========
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

main {
  // padding: 30px 0;
  // background-color: $colorGhostWhite;
  @media (min-width: $tabMin) and (max-width: $tabMax) {
    // padding: 20px 0;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    // padding: 10px 0;
  }
}

body.lock {
  overflow: hidden;
  padding-right: 17px;
  @media (min-width: $mobMin) and (max-width: $tabMax) {
    padding-right: 0;
  }
}

.slick-next,
.slick-prev {
  z-index: 50;
  border-radius: 20px;
  border: 1px solid $colorAntiFlashWhite;
  background: $colorWhite;
  padding: 10px;
  height: 40px;
  width: 40px;
  top: 30%;
  color: #131313;
  opacity: 0.4;

  &:hover {
    background: $colorWhite;
    opacity: 0.8;
  }

  &:focus {
    background: $colorWhite;
    opacity: 0.8;
  }

  &::before {
    color: $colorDarkLight;
    font-size: 18px;
    opacity: 0.7;
    margin: auto;

    &:hover {
      opacity: 0.8;
    }
  }
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}

.react-tabs__tab-list {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.react-tabs__tab {
  border-radius: 4px 4px 0 0;
  padding: 10px 20px;

  &:hover {
    color: $colorCyan;
  }
}

.react-tabs__tab--selected {
  &:hover {
    color: $colorBlack;
  }
}

.react-tabs__tab-panel--selected {
  border: 1px solid $colorSilverChalice;
  border-top: none;
}

.react-tabs__tab--selected {
  cursor: default;
}

.react-tabs__tab {
  &:focus {
    box-shadow: none;

    &::after {
      content: none;
    }
  }

  &:hover {
    color: $colorCyan;
  }
}

.react-tabs__tab--selected {
  color: $colorCyan;
}
.carousel-inner {
    // max-height: 700px!important;
  height: 100%!important;
}
.carousel-item {
  // max-height: 700px!important;
  height: 100%!important;
}
.modal-dialog {
  --bs-modal-width: 600px; /* Or any custom width */
}