.group__wrapperSelect{
    position: relative;
    border:1px solid #000000;
}
.group__arrow{
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    background-color: transparent;
    width: 100%;
}
.group__select{
    position: absolute;
    background-color: white;
    border-bottom: 1px solid #797979 ;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
    &::-webkit-scrollbar{
        width: 7px;
    }
    &::-webkit-scrollbar-track{
        width: 7px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb{
        width: 7px;
        border-radius: 14px;
        background-color: rgba(6, 6, 6, 0.149);
        transition: all .25s ease-out;
    }
    li{
        list-style: none;
        width: 100%;
        cursor: pointer;
        &:hover{
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
    }
}