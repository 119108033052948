@import '../../theme/styles/mixins';
@import '../../theme/styles/var';

// .catalog-grid {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
// }
// .catalog-grid__cell {
//     list-style: none;
//     width: 100%;
// }
// @media (min-width: $deskXMax) {
//     .catalog-grid {
//         grid-template-columns: repeat(5, 1fr);
//     }
//     .catalog-grid__cell {
//     }
// }
// @media (min-width: $deskXMin) and (max-width: $deskXMax) {
//     .catalog-grid {
//         grid-template-columns: repeat(4, 1fr);
//     }
//     .catalog-grid__cell {
//     }
// }

// @media (min-width: $deskMin) and (max-width: $deskMax) {
//     .catalog-grid {
//         grid-template-columns: repeat(3, 1fr);
//     }
//     .catalog-grid__cell {
//     }
// }
// @media (min-width: $tabMin) and (max-width: $tabMax) {
//     .catalog-grid {
//         grid-template-columns: repeat(2, 1fr);
//     }
//     .catalog-grid__cell {
//     }
// }

// @media (min-width: $mobMin) and (max-width: $mobMax) {
//     .catalog-grid {
//         grid-template-columns: repeat(2, 1fr);
//     }
//     .catalog-grid__cell {
//     }
// }

// @media (max-width: $mobMin) {
//     .catalog-grid {
//         grid-template-columns: repeat(1, 1fr);
//     }
//     .catalog-grid__cell {
//     }
// }
/* Загальні стилі для iPhone */
/* Загальні стилі для карток */
.custom-card-width {
    min-width: 265px;
    max-width: 400px !important;
    flex: 0 0 auto; /* Prevent flex shrinking or growing */
}

/* iPhone SE (2nd generation), iPhone 6, 7, 8 (375px * scale factor) */
@media (max-width: 750px) and (min-resolution: 2dppx) {
    .custom-card-width {
        min-width: 180px; /* Оптимізуємо для 2 карток у ряд */
    }
    .container {
        max-width: 100%;
    }
}

/* iPhone XR, iPhone 11 (414px * scale factor 2) */
@media (max-width: 828px) and (min-resolution: 2dppx) {
    .custom-card-width {
        min-width: 200px; /* Дві картки в ряд */
    }
}

/* iPhone 12 Mini, iPhone 13 Mini (360px * scale factor 3) */
@media (max-width: 1080px) and (min-resolution: 3dppx) {
    .custom-card-width {
        min-width: 180px; /* Відображення двох карток */
    }
    .container {
        padding: 0 10px;
    }
}

/* iPhone 12, 13, 14 (390px * scale factor 3) */
@media (max-width: 1170px) and (min-resolution: 3dppx) {
    .custom-card-width {
        min-width: 190px; /* Дві картки в ряд */
    }
}

/* iPhone 14 Pro Max (430px * scale factor 3) */
@media (max-width: 1290px) and (min-resolution: 3dppx) {
    .custom-card-width {
        min-width: 220px; /* Для великих екранів — 2 картки в ряд */
    }
}

/* Для портретної орієнтації */
@media screen and (orientation: portrait) {
    .custom-card-width {
        max-width: 100%; /* Картки займають всю ширину */
    }
}

/* Для горизонтальної орієнтації */
@media screen and (orientation: landscape) {
    .custom-card-width {
        min-width: 250px; /* Додаткова ширина для карток */
    }
}

/* Загальний медіа-запит для екранів з високою щільністю пікселів */
@media (min-resolution: 2dppx) {
    .custom-card-width {
        min-width: calc(100% / 2 - 10px); /* Розраховуємо ширину для двох карток */
    }
}
