@import '../../theme/styles/mixins';
@import '../../theme/styles/var';

.pageContainer {
  display: grid;
  grid-template-columns: calc(57% - 20px) 43%;
  gap: 20px;
}

.pageTitle {
  padding-bottom: 30px;
  font-size: 30px;
}

.ordersCantainer {
  padding: 20px;
  @extend %custom-shadow;
  @extend %custom-border;
  background-color: $colorWhite;
}

:global(.checkout__title) {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 30px 0;
}

@media screen and (max-width: $tabMax) {
  .pageContainer {
    grid-template-columns: 1fr
  }
}

@media screen and (max-width: $mobMax) {
  .ordersCantainer {
    width: 100%;
  }
} 