#brow {
    width:100%; /* Наприклад, ширина встановлена на 300px */
}

.selectContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.errorMessage {
    color: red;
    font-size: 12px;
    margin-top: 3px;
}
  