.registration__form-container-wrapper{
  height: 100%;
}
.name__link{
  cursor: pointer;
  text-decoration: underline transparent;
  transition: all ease-in .25s;
  &:hover{
    color:#000000;
    text-decoration: underline #000000;
  }
  &:active{
    color:#000000;
    text-decoration: underline #000000;
  }
}