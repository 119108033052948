@import '../../../../theme/styles/var';

.status {
  display: flex;
  align-items: center;
}

.iconChange {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  &:hover svg path,
  &:hover svg polygon {
    fill: $colorDarkLight;
  }
}

.statusText {
  text-align: right;
}

.select {
  padding: 3px 5px;
}
