@import '../../theme/styles/var';

.header {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  color: $colorDarkLight;
  font-size: 12px;
  margin: 5px 0 10px;
}

.headerQuantity {
  padding-right: 45px;
}

.headerPrice {
  padding-right: 35px;
}

.checkoutContainer {
  display: flex;
  justify-content: space-between;
  height: 110px;
}

.backContainer {
  align-self: flex-end;
  padding: 20px 15px;
}

.back {
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: $colorCyanLight;
  }
}

.checkoutBlock {
  padding: 10px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price {
  margin: 0;
  text-align: right;
}

.total {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
}
@media (max-width: $deskMin){
  .back {
      font-size: 14px;
  }
}
@media (max-width: $mobMax){
  .backContainer {
    display: none;
  }
  .header {
    display: none;
  }
  .checkoutContainer {
    justify-content: end;
  }
}