@import "../../../theme/styles/var";
.links__container{
    display: flex;
    padding: 0;
}
.link__wrapper{
    display: grid;
    place-content: center;
    // margin-right: 10px;
    // background-color: #252a84;
    color:#000000;
    
    list-style: none;
    border: none;
    border-radius: 5px;
    transition: box-shadow ease .2s;
    .link__wrapper-item{
        position: relative;
        color: #000000;
        padding: 5px;
        text-decoration: none;
        small{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &:hover{
        background-color: #bdbdbd3b;
    }
}
.link__wrapper-icon{
    display: grid;
    place-content: center;
    color:#000000;
    list-style: none;
    border: none;
    border-radius: 5px;
    transition: box-shadow ease .2s;
    .link__wrapper-item{
        color: #000000;
        padding: 5px;
        text-decoration: none;
    }
    &:hover{
        background-color: #bdbdbd3b;
    }
    span{
        text-align: center;
    }
}
.small__number{
    display: flex;
    background-color: blue;
    border: none;
    border-radius: 50%;
    color: #ffffff;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
}