.App {
  text-align: center;
  min-height: 100vh;
}
html {
  height: 100%;
}
.low{
  width: 50px;
}
.middle{
  min-width: 120px;
  max-width: 350px;
  overflow: hidden;
}
.table__cell{
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  &-item{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.table__wrapper{
  position: relative;
  overflow-x: auto;
}
.table__wrapper-second{
  overflow-x: inherit;

}
.table__main{
  width: 100%;
}
.table__head{
  position: sticky;
  top: 0;
  z-index: 1;
}
.table__body{
  overflow-x: auto;
}