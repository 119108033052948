@import '../../theme/styles/var';

.container {
  font-size: $fontSizeText;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $deskMin) and (max-width: $deskMax) {
    margin: 25px;
  }

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    margin: 20px;
    font-size: $fontSizeText_tab;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    margin: 10px;
    font-size: $fontSizeText_mobile;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
}

.arrow {
  position: relative;
  height: 30px;
  width: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid $colorLightGray;
  cursor: auto;

  & span::before {
    border-color: $colorLightGray;
  }
}

.arrowActive {
  border: 2px solid $colorDarkLight;
  transition: all 0.3s;
  cursor: pointer;

  & span::before {
    border-color: $colorDarkLight;
  }

  &:hover {
    border-color: $colorCyan;
    box-shadow: 0px 0px 32px -20px $colorBlackBackground;
    & span::before {
      border-color: $colorCyan;
      transition: all 0.3s;
    }
  }
}

.prev {
  &::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-bottom: 2px solid $colorDarkLight;
    border-left: 2px solid $colorDarkLight;
    transform: rotate(45deg);
    top: 8px;
    left: 10px;
  }
}

.next {
  &::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid $colorDarkLight;
    border-right: 2px solid $colorDarkLight;
    transform: rotate(45deg);
    top: 8px;
    right: 10px;
  }
}

.pageNumbersItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin: 0px 10px;
  border-radius: 5px;
  cursor: pointer;

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    margin: 0px 7px;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    margin: 0px 4px;
  }
}

.pageNumbersItem:hover .pageNumbers {
  color: $colorCyan;
}

.itemActive {
  border: 2px solid $colorCyan;
  color: $colorCyan;
  box-shadow: 0px 0px 32px -20px $colorBlackBackground;
}
