@import '../../theme/styles/var';

.cont {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  
  @media (min-width: $deskXMax) {
    max-width: $deskXMax;
  }

  @media (min-width: $deskXMin) and (max-width: $deskXMax) {
    max-width: $deskXMin;
  }

  @media (min-width: $deskMin) and (max-width: $deskMax) {
    max-width: $deskMin;
  }

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (max-width: $mobMin) {
    padding-right: 10px;
    padding-left: 10px;
    max-width: $mobMin;
  }
}
