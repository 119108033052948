@import '../../theme/styles/mixins';
@import '../../theme/styles/var';

.container {
  @extend %width-page;
  display: flex;
  justify-content: center;
  // @extend %custom-shadow;
  // @extend %custom-border;
  // padding: 20px;
  background-color: $colorWhite;
  position: relative;
  width: 100%;
  min-height: 100vh;
  @media (min-width: $tabMin) and (max-width: $tabMax) {
    width: 100%;
    min-height: 100vh;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    min-height: 100vh;
    width: 100%;
  }

  @media (max-width: $mobMin) {
    min-height: 100vh;
    width: 100%;
  }
}
