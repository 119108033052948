.required-indicator {
    position: relative;
    color: red;
    cursor: pointer;
}

.required-indicator:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the star */
    left: 50%;
    margin-left: -60px; /* Centers the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    border-radius: 5px; /* Add border-radius property */
}
