.carouselItemWrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      color: inherit;
  
      img {
        // width: 100%;
        // height: 100%;
        transition: transform 0.3s ease-in-out;
        text-align: center;
      }
  
      // &:hover img {
      //   transform: scale(1.05); // Slight zoom effect on hover
      // }
    }
  }
  