%custom-shadow {
  -webkit-box-shadow: 0px 0px 13px -3px rgba(38, 44, 48, 0.15);
  -moz-box-shadow: 0px 0px 13px -3px rgba(38, 44, 48, 0.15);
  box-shadow: 0px 0px 13px -3px rgba(38, 44, 48, 0.15);
}
%custom-border {
  border: 1px solid rgba(38, 44, 48, 0.15);
  border-radius: 5px;
}
%width-page {
  width: calc(100% - 280px);
}
