.item__cardWrapper { 
  width: 300px;
  height: 260px;
  position: relative;
  perspective: 800px;
  list-style-type: none;
}
.dashboard__main{
  display: flex;
  justify-content: space-between;
}
.product__img{
  width: 100px;
  img{
    width: 100%;
  }
}
.filter {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.filterWrapper{
  display: flex;
  gap: 10px;
  width: 350px;
  input{
    width: 250px;
  }
}