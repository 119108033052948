.container {
  margin-top: 20px;
  & div {
    display: block;
  }
  & label {
    position: static;
    display: inline-block;
    width: 150px;
    text-align: left;
  }
}

.textareaContainer {
  margin: 20px 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.label {
  position: absolute;
  right: 75%;
  text-align: right;
  font-size: 14px;
  padding-right: 10px;
}

.textarea {
  width: 75%;
  border: 1px solid #acacac;
  border-radius: 4px;
  vertical-align: middle;
  transition: border-color 0.1s;
  padding: 6px 8px;
  font-size: 16px;
  resize: none;
}
