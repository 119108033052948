@import '../../../theme/styles/var';
.product__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 100vh ;
    .product__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .product__imageWrapper{
            min-width: 265px;
            max-width: 600px;
            height: auto;
            aspect-ratio: 1/1;
            border: 1px solid #000000;
            .product__image{
                width: 100%;
                height: 100%;
            }

        }
        .product__block{
                padding: 10px;
                border: 1px solid #dddddd;
            &-price{
                padding: 10px;
                border-bottom: 1px solid #dddddd;
            }
        }
        .product__title{
            width: 100%;
            font-size: 24px;
            color: #000000;
            padding: 20px;
            text-align: start;
        }
        .product__price{

            &-current{
                color: #000000;
            }
            &-previous{
                color: red;
                text-decoration: line-through;
            }
        }
        .product__itemEnable{
            font-weight: 600;
            text-align: start;
            padding: 10px;
            .product__active{
                color: $colorLimeGreen;
            }
            .product__disactive{
                color: red;
            }
        }

        .product__btn{
            gap: 10px;
            padding: 10px;
            margin: 10px;
            svg{
                margin: 5px;
            }
        }
    }
    .group__wrapperSelect{
        position: relative;
        border:1px solid #000000;
        cursor: pointer;
    }
    .group__arrow{
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        background-color: transparent;
        width: 100%;
    }
    .group__select{
        position: absolute;
        background-color: white;
        border: 1px solid #797979 ;
        border-radius: 5px;
        width: 100%;
        height: 150px;
        overflow-y: auto;
        z-index: 1;
        &::-webkit-scrollbar{
            width: 7px;
        }
        &::-webkit-scrollbar-track{
            width: 7px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb{
            width: 7px;
            border-radius: 14px;
            background-color: rgba(6, 6, 6, 0.149);
            transition: all .25s ease-out;
        }
        li{
            list-style: none;
            width: 100%;
            cursor: pointer;
            &:hover{
                background-color: #dddddd;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            }
        }
    }
// @media (min-width: $deskXMax) {
//     max-width: $deskXMax;
//   }

//   @media (min-width: $deskXMin) and (max-width: $deskXMax) {
//     max-width: $deskXMin;
//   }

//   @media (min-width: $deskMin) and (max-width: $deskMax) {
//     max-width: $deskMin;
//   }

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 100vh ;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 100vh ;
  }

//   @media (max-width: $mobMin) {
//     padding-right: 10px;
//     padding-left: 10px;
//     max-width: $mobMin;
//   }
}