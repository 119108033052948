@import '../../../theme/styles/var';
.wrapper {
  padding: 15px;
  border-bottom: solid 1px $colorSilverChalice;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  // &:last-child {
    // border: none;
  // }

  // &:hover .delete {
  //   opacity: 1;
  // }
}
.quantityInput{
  width: 65px;
  padding: initial;
  border: none;
}
.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_count {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_name {
    flex-direction: column;
  }
}


// Delete
.delete {
  position: absolute;
  top: 0;
  right: 0px;
  width: 30px;
  text-align: center;
  color: red;
  padding: 10px 0;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}

.deleteBtn {
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: $colorCyanLight;
  }
}

//Name
.itemWrapper_name{
  display: flex;
}
.nameBlock {
  max-width: 310px; 
  font-size: 1rem;
}

.name {
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 5px;
  padding-right: 20px;
  color: $colorBlack;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: $colorCyanLight;
  }
}
.linkImg{
  text-align: left
}
// Price

.priceSales {
  display: flex;
  align-items: flex-end;
}

.currentPrice {
  // margin-right: 20px;
  color: $colorRedPigment;
  font-weight: 700;
  font-size: 14px;
}

.previousPrice {
  font-size: 12px;
  text-decoration: line-through;
  color: $colorDimGray;
}

.regularPrice {
  color: #051219;
  font-weight: 700;
  font-size: 14px;
}

// Quantity
.quantityBlock {
  display: flex;
  // border: 1px solid $colorSpanishGray;
  border-radius: 4px;
  // margin-right: 45px;
}

.quantityBtn {
  background-color: $colorWhite;
  border: none;
  cursor: pointer;
  font-size: 24px;
  width: 28px;
  border-radius: 4px;
  height: 36px;
}

.quantityInput {
  border: none;
  width: 25px;
  text-align: center;
}

.ended {
  position: absolute;
  right: 70px;
  bottom: 15px;
  font-size: 12px;
}

.price, .bottleCost {
  width: 100%;
  text-align: center;
  // margin-left: 25px;
  width: 100px;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
}
.bottleCost {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
:global(.checkout__orders-preview) {
  .container {
    @media (min-width: $deskXMin) and (max-width: $deskXMax) {
      padding: 10px;
    }

    @media (min-width: $deskMin) and (max-width: $deskMax) {
      padding: 5px;
    }
  }

  .delete {
    position: absolute;
    left: auto;
    right: 0;
    top: -5px;
    width: 30px;
    opacity: 1;
  }

  .deleteBtn {
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      font-size: 16px;
    }
  }

  .image {
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      width: 60px;
      height: 60px;
    }
  }

  .nameBlock {
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      font-size: 12px;
    }
  }

  .name {
    @media (min-width: $deskXMin) and (max-width: $deskXMax) {
      padding-right: 16px;
    }
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      padding-right: 10px;
    }
  }

  .currentPrice, .regularPrice {
    @media (min-width: $deskMin) and (max-width: $deskMax) {
      margin-right: 5px;
      font-size: 12px;
    }
  }

  .quantityBtn {
    @media (min-width: $deskXMin) and (max-width: $deskXMax) {
      font-size: 22px;
      width: 24px;
      height: 30px;
    }

    @media (min-width: $deskMin) and (max-width: $deskMax) {
      font-size: 16px;
      width: 15px;
      height: 20px;
    }
  }

  .quantityInput {
    @media (min-width: $deskXMin) and (max-width: $deskXMax) {
      font-size: 16px;
      width: 24px;
      height: 30px;
    }

    @media (min-width: $deskMin) and (max-width: $deskMax) {
      font-size: 14px;
      width: 15px;
    }
  }

  .price {
    @media (min-width: $deskXMin) and (max-width: $deskXMax) {
      margin-left: 10px;
      width: 80px;
    }

    @media (min-width: $deskMin) and (max-width: $deskMax) {
      margin-left: 10px;
      font-size: 12px;
      width: 70px;
    }
  }
}
@media (max-width: $tabMax) {
  .name {
    // max-width: 200px;
    max-width: 100%;
    font-size: .6rem;
  }
}
@media screen and (max-width: $mobMax) {
  .ended {
    right: 28px;
    bottom: 0px;
  }

  .previousPrice {
    font-size: 11px;
  }

  .currentPrice {
    font-size: 11px;
  }

  .price {
    position: absolute;
    right: -14px;
    bottom: 20px;
  }
  .name {
    max-width: 100px;
    font-size: .6rem;
  }
  .quantityBtn {
    font-size: 14px;
    width: 18px;
    border-radius: 4px;
    height: 28px;
  }
}

@media screen and (min-width: $deskMin) {
  .ended {
    bottom: 1px;
    right: 2px;
  }

  .quantityBtn {
    width: 22px;
  }
}
