@import '../../theme/styles/var';

.priceBlock {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.priceSales {
  display: flex;
  align-items: center;
  // @media (min-width: $mobMin) and (max-width: $tabMax) {
  //   display: block;
  //   text-align: center;
  // }
}

.currentPrice {
  margin-right: 20px;
  color: $colorRedPigment;
  font-weight: 700;
  font-size: 18px;

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    font-size: 16px;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    font-size: 14px;
  }

  &__slider {
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      margin-right: 15px;
      font-size: $fontSizeText_tab;
    }

    @media (min-width: $mobMin) and (max-width: $mobMax) {
      margin-right: 10px;
      font-size: $fontSizeText_mobile;
    }
  }
}

.previousPrice {
  font-size: 14px;
  text-decoration: line-through;
  color: $colorDimGray;

  @media (min-width: $mobMin) and (max-width: $tabMax) {
    // display: none;
  }

  &__slider {
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      font-size: $fontSizeText_tab;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      font-size: $fontSizeText_mobile;
    }
  }
}

.regularPrice {
  color: #051219;
  font-weight: 700;
  font-size: 18px;

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    font-size: 16px;
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    font-size: 14px;
  }

  &__slider {
    @media (min-width: $tabMin) and (max-width: $tabMax) {
      font-size: $fontSizeText_tab;
    }
    @media (min-width: $mobMin) and (max-width: $mobMax) {
      font-size: $fontSizeText_mobile;
    }
    @media (max-width: $mobMin) {
      font-size: $fontSizeText_mobile;
    }
  }
}
