.carouselSettings {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .carouselSelection,
  .addSlide {
    margin-bottom: 30px;
  }
  
  .label {
    font-size: 16px;
  }
  
  .input,
  .select {
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 7px 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .carouselItems {
    margin-top: 20px;
  }
  
  .subheading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .itemList {
    list-style-type: none;
    padding: 0;
  }
  
  .item {
    margin-bottom: 10px;
  }
  
  .noItems {
    color: #999;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  .customSelect {
    position: relative;
    width: 100%;
  
    .dropdown {
      position: relative;
      display: inline-block;
      width: 100%;
  
      .dropdownButton {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        text-align: left;
      }
  
      .dropdownContent {
        display: none;
        position: absolute;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        max-height: 200px;
        overflow-y: auto;
        z-index: 1000;
        width: 100%;
  
        .option {
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.2s;
  
          &:hover {
            background-color: #f0f0f0;
          }
  
          img.thumbnail {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 4px;
            margin-right: 10px;
          }
  
          span {
            font-size: 14px;
            color: #333;
          }
  
          &.selected {
            background-color: #e0e0e0;
          }
        }
      }
  
      &:hover .dropdownContent {
        display: block;
      }
    }
  }
  