details > summary {
  list-style-type: '>';
  font-weight: bold;
}

details[open] > summary {
  list-style-type: 'v';
  font-weight: bold;
}
.group-container {
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.group-title{
  font-size: 1.3rem;
  text-align: left;
}

.group-form{
position: relative;
}
.group-arrow{
  position: absolute;
  top: 0;
  right: 0;
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: lighter;
  font-size: 12px;
}

.inputProfile,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.personal-data__list {
  display: grid;
  gap:10px;
  margin: 10px 0;
}
@media (min-width: 1024px){
  .personal-data__list {
      grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 1024px){
  .personal-data__list {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
  }
}
  