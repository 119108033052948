.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Arial, sans-serif";
    line-height: 1.6;
  }
  
  .title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .sectionTitle {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .paragraph {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .footer {
    margin-top: 30px;
    text-align: center;
    font-size: 0.9em;
    color: gray;
  }
  