.registration-success {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    // margin-top: 50px; // Регулює відступ зверху
  
    h1 {
      color: green;
      font-size: 24px; // Розмір шрифту для заголовку
      margin-bottom: 20px; // Відступ знизу заголовку
    }
  
    p {
      color: #333;
      font-size: 18px; // Розмір шрифту для параграфів
      margin-bottom: 10px; // Відступ знизу параграфів
    }
  }