@import '../../theme/styles/var';
.header__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    color: #000000;
    transition: border-radius ease-out .2s;
    .logo__name{
        margin-left: 70px;
        color: #000000;
        font-size: calc(1.8rem + .6rem);
    }
    &:hover{
        border-radius: 0% 0% 10% 10%;
    }
    .logo__exit {
        display: inline-block;
        padding: 5px;
        cursor: pointer;
        svg{
            fill:  #000000;
             stroke: none;
            transition: all ease-in .3s;
        }
        &:hover svg{
            transform: scale(1.1);
        }
    }
}
.header__catalogs{
    position: fixed;
    top: 20px;
    left: 0;
    width: 100%;
    z-index: 12;
}
.logo__namehidden{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    height: 100px;
    .logo__img{
        width: 100%;
    }
}
// .logo__icon{
//     display: none;
//     width: 30px;
//     height: 30px;
// }

@media (min-width: $deskXMax) {
    .header__catalogs{
        top: 35px;
    }
  }

  @media (min-width: $deskXMin) and (max-width: $deskXMax) {
    .header__catalogs{
        top: 35px;
    }
  }

  @media (min-width: $deskMin) and (max-width: $deskMax) {
    .header__catalogs{
        top: 35px;
    }
  }

  @media (min-width: $tabMin) and (max-width: $tabMax) {
    // .logo__icon{
    //     display: flex;
    // }
    .logo__namehidden{
        display: inline-flex;
        width: 70px;
        height: 70px;
        .logo__img{
            width: 100%;
        }
    }
    .header__container{
        .logo__name{
            margin-left: 40px;
        }
    }
  }

  @media (min-width: $mobMin) and (max-width: $mobMax) {
    // .logo__icon{
    //     display: flex;
    // }
    .logo__namehidden{
        display: inline-flex;
        width: 50px;
        height: 50px;
        .logo__img{
            width: 100%;
        }
    }
    .header__container{
        .logo__name{
            margin-left: 40px;
        }
    }
  }

  @media (max-width: $mobMin) {
    // .logo__icon{
    //     display: flex;
    // }
    .logo__namehidden{
        display: inline-flex;
        width: 50px;
        height: 50px;
        .logo__img{
            width: 100%;
        }
    }
    .header__container{
        .logo__name{
            margin-left: 40px;
        }
    }
  }
