.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000000;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: -100%; /* Сховати меню за межами екрану за замовчуванням */
  transition: left 0.3s ease-in-out; /* Застосувати плавний перехід при зміні значення left */
  z-index: 10;
  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 14px;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 14px;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }

  &.open {
    left: 0; /* Показати меню при відкритті */
  }
}
.menu__wrapper{
  display: flex;
  margin-top: 40px;
  height: 90vh;
  overflow-y: auto;
  flex-direction: column;
  &::-webkit-scrollbar{
    width: 7px;
  }
  &::-webkit-scrollbar-track{
      width: 7px;
      border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb{
      width: 7px;
      border-radius: 14px;
      background-color: rgba(6, 6, 6, 0.149);
      transition: all .25s ease-out;
  }
}